import * as React from 'react';

function Moon(props) {
  return (
    <svg width={25} height={25} viewBox="0 0 512 512" {...props}>
      <path
        d="M492.533 393.514c-45.591 71.117-125.26 118.288-215.959 118.484-140.513.305-255.888-113.354-257.587-253.857C17.389 125.938 115.61 16.341 243.014.027c2.723-.349 4.497 2.766 2.842 4.956-30.719 40.661-48.931 91.286-48.931 146.177 0 134.063 108.676 242.738 242.738 242.738 17.004 0 33.592-1.744 49.607-5.075 2.689-.559 4.745 2.379 3.263 4.691z"
        fill="#efda45"
      />
      <path
        d="M489.276 388.819c2.684-.558 4.74 2.373 3.261 4.682C446.851 464.773 366.946 512 276.009 512 134.044 512 18.972 396.917 18.972 254.963c0-56.808 18.424-109.306 49.635-151.86-7.086 23.245-10.961 47.888-11.116 73.419-.867 143.032 114.009 258.635 257.044 258.629 51.831-.002 100.076-15.342 140.436-41.74a240.991 240.991 0 0034.305-4.592z"
        fill="#edc148"
      />
    </svg>
  );
}

export default Moon;
