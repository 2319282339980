import React, { Component } from 'react';
import { ToggleTheme } from './ToggleTheme';

export class Header extends Component {
  render() {
    return (
      <ToggleTheme
        changeTheme={this.props.changeTheme}
        lightTheme={this.props.lightTheme}
      />
    );
  }
}
