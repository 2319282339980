import styled from 'styled-components';

export const TitleH1 = styled.h1`
  font-size: 40px;
  font-weight: 600;
  margin: ${props => (props.noMargin ? '0px' : '10px 0px')};
`;

export const TitleH2 = styled.h1`
  font-size: 24px;
  font-weight: 400;
  margin: ${props => (props.noMargin ? '0px' : '10px 0px')};
`;

export const TitleH3 = styled.h3`
  font-size: 26px;
`;

export const Container = styled.div`
  padding: 3rem 10rem;
  background-color: ${props =>
    props.theme.secondary && !props.background
      ? props.theme.body
      : props.background};
  color: ${props =>
    props.theme.secondary && props.color ? props.theme.container : props.color};
  @media (max-width: 1100px) {
    padding: 3rem;
  }
`;

export const SectionDescription = styled.div`
  width: 100%;
  text-align: center;
`;

export const Heading = styled.div`
  font-size: 40px;
  margin: 2rem 0;
  text-align: center;
`;

export const SubHeading = styled.div`
  font-size: 26px;
  font-weight: 600;
  margin: 2rem 0;
`;

export const Paragraph = styled.p`
  font-size: 20px;
`;
