import * as React from 'react';

function Sun(props) {
  return (
    <svg width={25} height={25} viewBox="0 0 512 512" {...props}>
      <g fill="#ffd347">
        <circle cx={255.997} cy={255.997} r={144.824} />
        <path d="M256 56.849a7.736 7.736 0 01-7.737-7.737V7.737C248.263 3.463 251.727 0 256 0s7.737 3.463 7.737 7.737v41.376A7.736 7.736 0 01256 56.849zM152.563 84.568a7.732 7.732 0 01-6.707-3.869l-20.687-35.832a7.737 7.737 0 0113.401-7.737l20.687 35.832a7.737 7.737 0 01-6.694 11.606zM76.823 160.294a7.696 7.696 0 01-3.861-1.038L37.13 138.569a7.738 7.738 0 017.737-13.401l35.832 20.687a7.738 7.738 0 01-3.876 14.439zM49.112 263.737H7.737C3.464 263.737 0 260.274 0 256s3.464-7.737 7.737-7.737h41.376a7.737 7.737 0 11-.001 15.474zM41.005 387.869a7.737 7.737 0 01-3.875-14.438l35.832-20.687a7.738 7.738 0 017.737 13.401l-35.832 20.687a7.71 7.71 0 01-3.862 1.037zM131.862 478.74a7.738 7.738 0 01-6.693-11.607l20.687-35.832a7.738 7.738 0 0113.401 7.737L138.57 474.87a7.736 7.736 0 01-6.708 3.87zM256 512a7.736 7.736 0 01-7.737-7.737v-41.376c0-4.274 3.464-7.737 7.737-7.737s7.737 3.463 7.737 7.737v41.376A7.736 7.736 0 01256 512zM380.138 478.74a7.732 7.732 0 01-6.707-3.869l-20.687-35.832a7.737 7.737 0 0113.401-7.737l20.687 35.832a7.737 7.737 0 01-6.694 11.606zM470.995 387.869a7.696 7.696 0 01-3.861-1.038l-35.832-20.687a7.738 7.738 0 017.737-13.401l35.832 20.687a7.738 7.738 0 01-3.876 14.439zM504.263 263.737h-41.376c-4.273 0-7.737-3.463-7.737-7.737s3.464-7.737 7.737-7.737h41.376c4.273 0 7.737 3.463 7.737 7.737s-3.464 7.737-7.737 7.737zM435.177 160.294a7.737 7.737 0 01-3.875-14.438l35.832-20.687a7.738 7.738 0 017.737 13.401l-35.832 20.687a7.71 7.71 0 01-3.862 1.037zM359.437 84.568a7.738 7.738 0 01-6.693-11.607l20.687-35.832a7.738 7.738 0 0113.401 7.737l-20.687 35.832a7.735 7.735 0 01-6.708 3.87z" />
      </g>
      <path
        d="M256 111.18c-5.242 0-10.418.286-15.516.828 72.685 7.743 129.303 69.252 129.303 143.991s-56.619 136.249-129.303 143.992c5.098.544 10.273.828 15.516.828 79.982 0 144.82-64.838 144.82-144.82S335.983 111.18 256 111.18z"
        fill="#ffbe31"
      />
    </svg>
  );
}

export default Sun;
