import React from 'react';
import styled from 'styled-components';
import GitIcon from '../icons/GitIcon';
import LinkedinIcon from '../icons/Linkedin';

const Icons = styled.div`
  :hover {
    opacity: 50%;
  }
  fill: ${props => (props.theme.secondary ? props.theme.container : undefined)};
`;

class Footer extends React.Component {
  render() {
    return (
      <div>
        <div className="card-body d-flex justify-content-center">
          <ul className="nav">
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://github.com/imourtz"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icons>
                  <GitIcon />
                </Icons>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://www.linkedin.com/in/imourtz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icons>
                  <LinkedinIcon />
                </Icons>
              </a>
            </li>
          </ul>
        </div>
        <div className="d-flex align-items-bottom justify-content-center">
          <p>&copy; {new Date().getFullYear()} Ioanna Mourtziopoulou</p>
        </div>
      </div>
    );
  }
}

export default Footer;
