import React from 'react';
import styled from 'styled-components';
import Moon from '../icons/Moon';
import Sun from '../icons/Sun';

const ThemeButton = styled.button`
margin-left: 1rem;
  border: none;
  background-color: ${props =>
    props.theme.secondary ? props.theme.body : undefined};}  
`;

export const ToggleTheme = ({ changeTheme, lightTheme }) => (
  <ThemeButton onClick={changeTheme}>
    {lightTheme ? <Moon /> : <Sun />}
  </ThemeButton>
);
