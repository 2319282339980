import React from 'react';
import { Link } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import { Header } from '../components/Header';

const light = {
  main: '#2a2a2a',
  secondary: 'white',
  link: 'white',
  brand: 'rebeccapurple',
  container: '#2f3136;',
  body: 'white',
  carousel: '#f0f0f0',
};

const dark = {
  main: 'white',
  secondary: '#2f3136;',
  container: '#fff7eb',
  link: 'white',
  brand: 'palevioletred',
  body: '#2f3136;',
  carousel: '#2f3136;',
};

const LayoutEl = styled.div`
  background-color: ${props =>
    props.theme.secondary ? props.theme.body : undefined};
  color: ${props => (props.theme.secondary ? props.theme.main : undefined)};
  transition: color 0.2s ease-out, background 0.2s ease-out;
`;

const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 10rem 1rem 10rem;
  padding-top: 1rem;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`;

const Logo = styled(Link)`
  font-family: Pacifico, cursive;
  font-size: 30px;
  color: ${props =>
    props.theme.secondary ? props.theme.container : undefined};
  :hover {
    text-decoration: none;
    color: #666666;
  }
`;

const NavTabs = styled.div`
  display: flex;
`;

const Tabs = styled(Link)`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  margin-left: 2rem;
  color: ${props =>
    props.theme.secondary ? props.theme.container : undefined};

  :hover {
    text-decoration: none;
    color: #666666;
  }
`;

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lightTheme: true };
    this.changeTheme = this.changeTheme.bind(this);
  }

  componentDidMount() {
    const localStorageLayout = localStorage.getItem('lightTheme');
    if (localStorageLayout) {
      this.setState({ lightTheme: JSON.parse(localStorageLayout) });
    }
  }

  changeTheme() {
    this.setState({
      lightTheme: !this.state.lightTheme,
    });
    localStorage.setItem('lightTheme', !this.state.lightTheme);
  }
  render() {
    console.log(this.state.lightTheme);
    const { children } = this.props;
    return (
      <ThemeProvider theme={this.state.lightTheme ? light : dark}>
        <LayoutEl>
          <NavBar>
            <div>
              <Logo to="/">
                <strong>imourtz</strong>
              </Logo>
            </div>
            <NavTabs>
              <Tabs activeStyle={{ fontWeight: '600' }} to="/about-me">
                About Me
              </Tabs>
              <Tabs activeStyle={{ fontWeight: '600' }} to="/projects">
                Projects
              </Tabs>
              <Header
                changeTheme={this.changeTheme}
                lightTheme={this.state.lightTheme}
              />
            </NavTabs>
          </NavBar>
          {children}
        </LayoutEl>
      </ThemeProvider>
    );
  }
}

export default Navigation;
